import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useForm } from "react-hook-form"
import "../styles/contact-forms.scss"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default ({ location }) => {
    const { contactEmail } = useStaticQuery(graphql`
    query GeneralContactQuery {
        contactEmail: allFile(filter: {sourceInstanceName: {eq: "siteOptions"}}) {
          edges {
            node {
              childLeagueJson {
                contactEmail
              }
            }
          }
        }
      }

    `)

    const emailTo = contactEmail.edges[0].node.childLeagueJson.contactEmail

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formSuccess, setFormSuccess] = useState(false)

    const { register, handleSubmit, errors } = useForm()

    useEffect(() => {
        if (formSuccess) navigate("/thank-you")
    }, [formSuccess])

    const onSubmit = formData => {
        formData.contactEmail = emailTo

        setIsSubmitting(true)

        fetch("/.netlify/functions/api/contact-us", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then(res => res.json())
            .then(res => {
                setIsSubmitting(false)
                setFormSuccess(true)
            })
    }

    const errorBorderStyles = {
        borderColor: "#DC202F",
        borderLeftWidth: "15px"
    }

    return (
        <Layout location={location} cssPageName="general-contact">
            <SEO title="Contact Us" />
            <h1 className="title">Contact Us</h1>
            <p className="sub-title">Fans? Sponsors? Job Applicants? Anyone else? This is your form!</p>
            <form className="general-contact-form" onSubmit={handleSubmit(onSubmit)}>
                <label className="form-label" htmlFor="name">Your Name
                    <input type="text" name="name" ref={register({ required: true, maxLength: 80 })} style={errors.name && errorBorderStyles} />
                </label>
                <label className="form-label" htmlFor="email">Your Email
                    <input type="text" name="email" ref={register({ required: true, pattern: /^\S+@\S+$/i })} style={errors.email && errorBorderStyles} />
                </label>
                <label className="form-label" htmlFor="mobileNumber">Your Phone Number
                    <input type="tel" name="mobileNumber" ref={register({ required: true, minLength: 6, maxLength: 12 })} style={errors.mobileNumber && errorBorderStyles} />
                </label>
                <label className="form-label" htmlFor="message">Your Message
                    <textarea className="textarea" name="message" ref={register({ required: true })} style={errors.message && errorBorderStyles} />
                </label>
                <div className="button-container">
                    <input className="submission-button" type="submit" value={isSubmitting ? "..." : "Send"} disabled={isSubmitting} />
                </div>
            </form>
        </Layout>
    )
}